/* eslint-disable camelcase */
import React from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import { formatDate } from '@util/dateFunctions';
import { Tag, Text } from '@intus-ui';
import { useHistory } from 'react-router';
import { useFeatureFlag } from '@util';
import { FormattedAdmit, CurrentlyAdmittedTableProps } from './types';
import tableConfig from './table.config';

// Assuming you have defined the type of color with specific values:
type Color =
  | 'blue'
  | 'brown'
  | 'gray'
  | 'green'
  | 'indigo'
  | 'maroon'
  | 'orange'
  | 'pink'
  | 'purple'
  | 'teal'
  | 'pine';

export const statusToColor: { [key: string]: Color } = {
  COMPLETED: 'green',
  'IN PROGRESS': 'orange',
  READMISSION: 'blue',
  'NOT STARTED': 'gray',
};

// CURRENTLY ADMITTED TABLE AND COLUMN CONFIG
function getCurrentlyAdmittedColumns(
  history: any,
  featureIsActive: boolean
): MRT_ColumnDef<FormattedAdmit>[] {
  const columns: MRT_ColumnDef<FormattedAdmit>[] = [
    {
      accessorKey: 'name',
      header: 'Participant Name',
      enableColumnFilter: false,
      accessorFn: (row: FormattedAdmit) => {
        return (
          <div>
            <Text
              type="subtitle"
              color="link"
              onClick={() => history.push(`/patient/${row.patient.id}/admits`)}
            >
              {`${row.patient.firstName} ${row.patient.lastName}`}
            </Text>
          </div>
        );
      },
    },
    // {
    //   accessorKey: 'acuity',
    //   header: 'Acuity',
    //   maxSize: 50,
    //   sortingFn: 'acuitySorting',
    //   filterVariant: 'select',
    //   filterFn: 'acuityFilter',
    //   filterSelectOptions: [
    //     { label: 'Low Risk', value: 'LOW' },
    //     { label: 'Moderate Risk', value: 'MODERATE' },
    //     { label: 'High Risk', value: 'HIGH' },
    //     { label: 'Highest Risk', value: 'HIGHEST' },
    //     { label: 'Insufficient Data', value: 'INSUF' },
    //   ],
    //   accessorFn: (row: FormattedAdmit) => {
    //     return (
    //       <div style={{ display: 'flex', justifyContent: 'center' }}>
    //         <RiskBadge
    //           style={undefined}
    //           customText={row.patient?.acuity?.riskGroup}
    //           riskGroup={row.patient?.acuity?.riskGroup}
    //           isDisenrolled={row.patient?.disenrollmentDate != null}
    //           smallText
    //         />
    //       </div>
    //     );
    //   },
    // },
    {
      accessorKey: 'admitDate',
      enableColumnFilter: false,
      header: 'Admitted',
      Cell: ({ cell }) => <Text>{formatDate(cell.getValue<string>(), 'MM/DD/YYYY')}</Text>,
    },
    {
      accessorKey: 'los',
      header: 'LOS',
      enableColumnFilter: false,
      Cell: ({ cell }) => {
        const los = cell.getValue<string>();
        return <Text>{los}</Text>;
      },
    },
    {
      accessorKey: 'admitType',
      header: 'Admit Type',
      enableColumnFilter: false,
      Cell: ({ cell }) => {
        const admitType = cell.getValue<string>();
        return <Text>{admitType}</Text>;
      },
    },
    {
      accessorKey: 'location',
      header: 'Location',
      enableColumnFilter: false,
      minSize: 200,
      Cell: ({ cell }) => {
        const location = cell.getValue<string>();
        return <Text>{location}</Text>;
      },
    },
    {
      accessorKey: 'principalDx',
      header: 'Principal Diagnoses',
      enableColumnFilter: false,
      minSize: 250,
      enableSorting: false,
      Cell: ({ cell }) => {
        const principalDxArray = cell.getValue<{ code: string; description: string }[]>();
        return principalDxArray?.map((dx) => {
          return <Text>{`${dx.description} (${dx.code})`}</Text>;
        });
      },
    },
  ];
  if (featureIsActive) {
    columns.splice(2, 0, {
      accessorKey: 'arnStatus',
      header: 'ARN Status',
      enableColumnFilter: false,
      enableSorting: false,
      accessorFn: (row: FormattedAdmit) => {
        const { arnStatus } = row;
        if (row.arnStatus == null) {
          return <div />;
        }

        const color: Color = statusToColor[arnStatus! as keyof typeof statusToColor] || 'grey';
        return <Tag color={color}>{row.arnStatus}</Tag>;
      },
    });
  }

  return columns;
}

export const CurrentlyAdmittedTable: React.FC<CurrentlyAdmittedTableProps> = ({ data }) => {
  const { featureIsActive } = useFeatureFlag('admit-review-notes');

  const history = useHistory();
  const columns = getCurrentlyAdmittedColumns(history, featureIsActive);
  const table = useMaterialReactTable({
    renderEmptyRowsFallback: () => <Text>No participants currently admitted.</Text>,
    columns,
    data,
    sortingFns: {
      acuitySorting: (rowA: any, rowB: any): number => {
        const rowAContents = rowA.original;
        const rowBContents = rowB.original;
        return rowAContents.patient?.acuity?.acuityScore < rowBContents.patient?.acuity?.acuityScore
          ? 1
          : -1;
      },
    },
    filterFns: {
      acuityFilter: (row, _columnId, filterValue): boolean => {
        const {
          patient: {
            acuity: { riskGroup },
          },
        } = row.original;
        if (riskGroup === filterValue) return true;
        if (!riskGroup && filterValue === 'INSUF') return true;
        return false;
      },
    },
    initialState: {
      columnPinning: { left: ['participantName'] },
      sorting: [
        {
          id: 'admitDate',
          desc: true,
        },
      ],
      showColumnFilters: true,
    },
    ...tableConfig,
    enableSorting: true,
    defaultColumn: {
      minSize: 25,
      size: 50,
      maxSize: 200,
    },
    muiTableContainerProps: {
      sx: {
        height: 'calc(50vh - 100px)',
      },
    },
  });

  return <MaterialReactTable table={table} />;
};

// DISCHARGED TABLE AND COLUMN CONFIG
function getDischargedColumns(
  history: any,
  featureIsActive: boolean // handleFilterByAcuity: any
): MRT_ColumnDef<FormattedAdmit>[] {
  const columns: MRT_ColumnDef<FormattedAdmit>[] = [
    {
      accessorKey: 'name',
      header: 'Participant Name',
      enableColumnFilter: false,
      accessorFn: (row: FormattedAdmit) => {
        return (
          <div>
            <Text
              type="subtitle"
              color="link"
              onClick={() => history.push(`/patient/${row.patient.id}/admits`)}
            >
              {`${row.patient.firstName} ${row.patient.lastName}`}
            </Text>
          </div>
        );
      },
    },
    // {
    //   accessorKey: 'acuity',
    //   size: 80,
    //   header: 'Acuity',
    //   sortingFn: 'acuitySorting',
    //   filterVariant: 'select',
    //   filterFn: 'acuityFilter',
    //   filterSelectOptions: [
    //     { label: 'Low Risk', value: 'LOW' },
    //     { label: 'Moderate Risk', value: 'MODERATE' },
    //     { label: 'High Risk', value: 'HIGH' },
    //     { label: 'Highest Risk', value: 'HIGHEST' },
    //     { label: 'Insufficient Data', value: 'INSUF' },
    //   ],
    //   accessorFn: (row: FormattedAdmit) => {
    //     return (
    //       <div style={{ display: 'flex', justifyContent: 'center' }}>
    //         <RiskBadge
    //           style={undefined}
    //           customText={row.patient?.acuity?.riskGroup}
    //           riskGroup={row.patient?.acuity?.riskGroup}
    //           isDisenrolled={row.patient?.disenrollmentDate != null}
    //           smallText
    //         />
    //       </div>
    //     );
    //   },
    // },
    {
      accessorKey: 'admitDate',
      header: 'Admitted',
      enableColumnFilter: false,

      Cell: ({ cell }) => <Text>{formatDate(cell.getValue<string>(), 'MM/DD/YYYY')}</Text>,
    },
    {
      accessorKey: 'dischargeDate',
      header: 'Discharged',
      enableColumnFilter: false,
      Cell: ({ cell }) => {
        const dischargeDate = cell.getValue<string>();
        return dischargeDate ? <Text>{formatDate(dischargeDate, 'MM/DD/YYYY')}</Text> : '';
      },
    },
    {
      accessorKey: 'los',
      header: 'LOS',
      enableColumnFilter: false,
      Cell: ({ cell }) => {
        const los = cell.getValue<string>();
        return <Text>{los}</Text>;
      },
    },
    {
      accessorKey: 'admitType',
      enableColumnFilter: false,
      header: 'Admit Type',
      Cell: ({ cell }) => {
        const admitType = cell.getValue<string>();
        return <Text>{admitType}</Text>;
      },
    },
    {
      accessorKey: 'location',
      enableColumnFilter: false,
      header: 'Location',
      Cell: ({ cell }) => {
        const location = cell.getValue<string>();
        return <Text>{location}</Text>;
      },
    },
    {
      accessorKey: 'principalDx',
      header: 'Principal Diagnoses',
      enableColumnFilter: false,
      enableSorting: false,
      minSize: 250,
      Cell: ({ cell }) => {
        const principalDxArray = cell.getValue<{ code: string; description: string }[]>();
        return principalDxArray?.map((dx) => {
          return <Text>{`${dx.description} (${dx.code})`}</Text>;
        });
      },
    },
    {
      accessorKey: 'dischargeDisposition',
      enableColumnFilter: false,
      header: 'Discharge Disposition',
      minSize: 150,
      Cell: ({ cell }) => {
        const text = cell.getValue<string>();
        return <Text>{text}</Text>;
      },
    },
  ];

  if (featureIsActive) {
    columns.splice(2, 0, {
      accessorKey: 'arnStatus',
      header: 'ARN Status',
      enableColumnFilter: false,
      enableSorting: false,
      accessorFn: (row: FormattedAdmit) => {
        const { arnStatus } = row;
        if (row.arnStatus == null) {
          return <div />;
        }

        const color: Color = statusToColor[arnStatus! as keyof typeof statusToColor] || 'grey';
        return <Tag color={color}>{row.arnStatus}</Tag>;
      },
    });
  }

  return columns;
}

export const DischargedTable: React.FC<CurrentlyAdmittedTableProps> = ({ data }) => {
  const { featureIsActive } = useFeatureFlag('admit-review-notes');
  const history = useHistory();
  const columns = getDischargedColumns(history, featureIsActive);
  const table = useMaterialReactTable({
    renderEmptyRowsFallback: () => <Text>No admits during this time range.</Text>,
    columns,
    data,
    sortingFns: {
      acuitySorting: (rowA: any, rowB: any): number => {
        const rowAContents = rowA.original;
        const rowBContents = rowB.original;
        return rowAContents.patient?.acuity?.acuityScore < rowBContents.patient?.acuity?.acuityScore
          ? 1
          : -1;
      },
    },
    filterFns: {
      acuityFilter: (row, _columnId, filterValue): boolean => {
        const {
          patient: {
            acuity: { riskGroup },
          },
        } = row.original;
        if (riskGroup === filterValue) return true;
        if (!riskGroup && filterValue === 'INSUF') return true;
        return false;
      },
    },
    initialState: {
      columnPinning: { left: ['participantName'] },
      sorting: [
        {
          id: 'admitDate',
          desc: true,
        },
      ],
      showColumnFilters: true,
    },
    ...tableConfig,
    enableSorting: true,
    defaultColumn: {
      minSize: 25,
      size: 50,
      maxSize: 200,
    },
    muiTableContainerProps: {
      sx: {
        height: 'calc(50vh - 100px)',
      },
    },
  });

  return <MaterialReactTable table={table} />;
};
