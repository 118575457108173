/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { clearStats } from '@global-state/redux/profileSlice';
import { getIncidentsByPatient, getPatientByIdAPI } from '@api/api';
import { getLogger } from '@util/logger';

const log = getLogger('patientDetailSlice');

const patientDetailSlice = createSlice({
  name: 'patientDetailSlice',
  initialState: {
    patient: undefined,
    id: undefined,
    error: undefined,
    selectedAcuity: 0,
    patientIncidents: undefined,
    incidentsByCategory: {
      Burn: [],
      Fall: [],
      General: [],
      Infection: [],
      MedError: [],
    },
  },
  reducers: {
    // The payload should be a patientDetailPatientPropType.
    setPatient(state, action) {
      state.patient = action.payload;
    },

    // This way the ppt focus view always has the patientid saved
    setPptId(state, action) {
      state.pptId = action.payload;
    },

    // This allows the patient page to display an error.
    setError(state, action) {
      state.error = action.payload;
    },

    // This is triggered together with the retry button.
    clearPatientAndError(state) {
      state.patient = undefined;
      state.pptId = undefined;
      state.error = undefined;
      state.selectedAcuity = 0;
    },

    // Adds an acuity to the current patient.
    addAcuityToPatient(state, action) {
      if (!state.patient.acuityLevels) {
        state.patient.acuityLevels = [action.payload];
      } else {
        state.patient.acuityLevels.push(action.payload);
      }
    },
    setPatientIncidents(state, action) {
      state.patientIncidents = action.payload;
    },
    setIncidentsByCategory(state, action) {
      const copy = {
        Burn: [],
        Fall: [],
        General: [],
        Infection: [],
        MedError: [],
      };
      Object.keys(action.payload).forEach((key) => {
        copy[key] = action.payload[key];
      });
      state.incidentsByCategory = copy;
    },
  },
});

export default patientDetailSlice.reducer;
export const {
  setPatient,
  setPptId,
  setError,
  clearPatientAndError,
  addAcuityToPatient,
  setPatientIncidents,
  setIncidentsByCategory,
} = patientDetailSlice.actions;

export const getPatient = (patientId) => async (dispatch) => {
  dispatch(clearPatientAndError());
  dispatch(setPptId(patientId));
  dispatch(clearStats());
  const errorMessage = `There was a problem fetching participant ${patientId}.`;

  try {
    const result = await getPatientByIdAPI(patientId);
    if (result.ok) {
      const {
        id,
        firstName,
        lastName,
        // patientNotes,
        phoneNumber,
        dateOfBirth,
        // address,
        acuityLevels,
        team,
        facility,
        enrollmentDate,
        disenrollmentDate,
        disenrollmentReason,
        organization,
      } = await result.json();

      // const { city, secondaryAddress, state, street, zip } = address || {};
      dispatch(
        setPatient({
          id,
          firstName,
          lastName,
          // patientNotes,
          phoneNumber,
          dateOfBirth,
          // address: {
          //   city,
          //   secondaryAddress,
          //   state,
          //   street,
          //   zip,
          // },
          acuityLevels,
          team,
          facility,
          enrollmentDate,
          disenrollmentDate,
          disenrollmentReason,
          organizationName: organization?.name,
        })
      );
    } else if (result.status === 404) {
      dispatch(setError(`No participant with ID ${patientId} exists.`));
    } else {
      dispatch(setError(errorMessage));
    }
  } catch (err) {
    log.error(err);
    dispatch(setError(errorMessage));
  }
};

export const getPatientIncidents = (id) => async (dispatch) => {
  try {
    const res = await getIncidentsByPatient(id);
    if (res.ok) {
      const resJ = await res.json();
      dispatch(setPatientIncidents(resJ));
      let categories = {
        Burn: [],
        Fall: [],
        General: [],
        Infection: [],
        MedError: [],
      };
      if (resJ.length === 0) {
        dispatch(setIncidentsByCategory(categories));
      } else {
        categories = {};
      }
      resJ.forEach((incident) => {
        if (!categories[incident.incidentType]) categories[incident.incidentType] = [];
        categories[incident.incidentType] = categories[incident.incidentType].concat(incident);
      });
      dispatch(setIncidentsByCategory(categories));
    }
  } catch (err) {
    log.error(err);
  }
};
